.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  width: auto;
}
.main-container {
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: auto;
}
.form-container {
  padding: 0.5rem;
  width: 50rem;
  border: 1px solid rgb(181, 178, 178);
  border-radius: 10px;
}
.result-container {
  padding: 0.5rem;
  border-radius: 10px;

  border: 1px solid rgb(181, 178, 178);
  margin-top: 1rem;
}

.total-win {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}
